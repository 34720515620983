body {
    margin: 0;
    overflow: hidden;
    font-family: Arial;
}

.iframe-bubble {
    width: 100%;
    height: 100vh;
}
